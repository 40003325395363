<template>
  <div class="telemedicine">
    <h2>Telemedicine</h2>

    <p> 
      Recent changes in federal law (as of March 17, 2020) now allow us
      to offer telemedicine, though this may be subject to change in the future. Telemedicine
      appointments with Heather Pardun, FNP, Jenna Kaup, FNP and Dr. Pete Lueninghoener can be
      completed from the privacy of your home via any device which supports live streaming video.
      These include your home computer, laptop, iPad or smart phone. Telemedicine medicine appointments
      for Dr. Pete are currently limited. You MUST SCHEDULE YOUR APPOINTMENT WITH OUR OFFICE AS YOU
      WOULD NORMALLY, EXCEPT ASK FOR A TELEMEDICINE APPOINTMENT. These appointments can be scheduled
      during the mornings or afternoons and are not subject to the restrictions caused by the current
      COVID-19 pandemic. Our scheduling personnel and nurses will try to assist you in the appropriateness
      of a telemedicine appointment. No special internet downloads are needed for your part, however,
      GOOGLE CHROME as the browser works the best. Simply click on the link below just prior to the
      time of your appointment with your provider and you will enter a private online virtual waiting
      room until she is available. This is a secure website specifically for medical providers.
      The website does not record video so the privacy of your appointment is maintained and is
      HIPPA compliant. The providers will be viewing you on their computer in their office so the
      appointment will be private at our end of the video stream. It will be your responsibility to be
      in a private area and whether or not you want to be accompanied by one or more persons. Currently
      Medicare is participating but other insurances are being asked on a national scale to participate.
      Check with your health insurance carrier if they are participating and encourage them to participate
      if they do not currently.
    </p>
    <p> 
      Other telemedicine services also offered include virtual check ins. This
      includes our providers responding to your concerns via telephone, captured audio\video (pictures),
      secure text messaging, or email. These will be initiated by contacting the provider’s specific
      nurse with your information/question(s). Then our practitioners will contact you after analyzing
      the information and consulting your chart.  Medicaid patients are eligible for telephone evaluation
      and management services which will be initiated similarly to the virtual check ins. All applicable
      co-pays and deductibles will apply for all types of telemedicine just as if you are being seen in
      our clinic building. These charges will be billed on your monthly statement.
    </p>
    <p> 
      Please know we are working hard to continue to provide you with the
      best possible affordable healthcare we can in this changing post-pandemic environment. We are
      constantly updating our processes and accessing technology so that we can provide the most up to
      date care while maintaining your safety and the safety of our staff.
    </p>
  </div>
</template>

<script>
  export default {
      name: 'Telemedicine'
  }
</script>

<style scoped>
  .telemedicine {
    width: 70%;
    margin: auto;
  }
</style>